












import { Vue } from "vue-property-decorator";

export default class AccordionSection extends Vue {}
