var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (!_vm.$route.query.handle)?_c('div',[_c('header',{staticClass:"header uk-flex-none"},[_c('Header',{staticClass:"start"})],1),_vm._v(" "),_vm._m(0),_vm._v(" "),_c('div',{staticClass:"uk-container uk-container-xlarge"},[_c('PageHeadline',{scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(" UMBRELLA ")]},proxy:true},{key:"titleItalic",fn:function(){return [_vm._v(" COLLECTIONS")]},proxy:true}],null,false,908255962)}),_vm._v(" "),_c('ul',{attrs:{"uk-accordion":"multiple: true"}},_vm._l((_vm.umbrellaType),function(type){return _c('AccordionSection',{key:type,staticClass:"uk-card uk-open uk-card-default uk-card-body",scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('h2',{staticClass:"uk-padding-remove uk-margin-remove"},[_vm._v("\n            "+_vm._s(type)+"\n          ")])]},proxy:true},{key:"content",fn:function(){return [_c('div',{staticClass:"\n              uk-child-width-1-2@s\n              uk-child-width-1-4@l\n              uk-flex-center\n              uk-grid-match\n              uk-grid-small\n              uk-text-center\n            ",attrs:{"uk-grid":""}},_vm._l((_vm.umbrellas.filter(
                function (c) { return c.umbrella_type === type; }
              )),function(collection){return _c('div',{key:collection._id,staticClass:"uk-inline uk-padding uk-padding-small"},[_c('div',{staticClass:"item uk-inline",on:{"click":function($event){return _vm.selectProduct(collection)}}},[_c('div',[_c('img',{staticClass:"uk-margin-auto uk-display-block",attrs:{"src":_vm.getImg(collection),"width":"285"}}),_vm._v(" "),_c('div',{staticClass:"uk-overlay uk-position-center uk-text-center"},[_c('h1',[_vm._v("\n                      "+_vm._s(collection.display_name &&
                        collection.display_name.indexOf(" ") != -1
                          ? _vm.formatTitle(collection.display_name, "a")
                          : collection.display_name)+"\n                      "),_c('em',{staticStyle:{"display":"block"}},[_vm._v(_vm._s(collection.display_name &&
                        collection.display_name.indexOf(" ") != -1
                          ? _vm.formatTitle(collection.display_name, "b")
                          : null))])])])])])])}),0)]},proxy:true}],null,true)})}),1)],1)]):_vm._e()}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"uk-container"},[_c('div',{staticClass:"uk-padding uk-padding-remove-bottom"},[_c('img',{staticClass:"uk-margin-remove-vertical uk-align-center sd-logo",attrs:{"src":"https://frankford.b-cdn.net/sd/ui_assets/ui/sd-logo-tm.png?width=400"}})]),_vm._v(" "),_c('div',{staticClass:"contest-banner-container uk-width-1-1 uk-align-center",attrs:{"hidden":""}},[_c('div',{staticClass:"contest-banner-hand uk-position-top-left"},[_c('img',{attrs:{"src":"https://frankford.b-cdn.net/sd/ui_assets/contest/contest-hand.png?height=190"}})]),_vm._v(" "),_c('div',{staticClass:"contest-banner"},[_c('div',{staticClass:"intro uk-light uk-text-default"},[_c('span',{staticClass:"contest-label"},[_c('img',{attrs:{"src":"https://frankford.b-cdn.net/sd/ui_assets/contest/contest-label.png?width=140"}})]),_vm._v("\n          Design the\n          "),_c('span',{staticClass:"uk-text-bold uk-text-italic"},[_vm._v("Most Beautiful")]),_vm._v(" or\n          the\n          "),_c('span',{staticClass:"uk-text-bold uk-text-italic"},[_vm._v("Ugliest")]),_vm._v(" umbrella\n          for a chance to win an exceptional prize!\n        ")]),_vm._v(" "),_c('div',{staticClass:"directions uk-light uk-text-small"},[_c('span',{staticClass:"uk-text-bold uk-text-uppercase"},[_vm._v("How do I enter?")]),_vm._v(" "),_c('span',{staticClass:"steps"},[_vm._v("1. Create your version of a beautiful or ugly umbrella")]),_vm._v(" "),_c('span',{staticClass:"steps"},[_vm._v("2. Submit your design and fill out the form")]),_vm._v(" "),_c('span',{staticClass:"steps"},[_vm._v("3. Come back to the showroom on Thursday to find out if you\n            won!")])])])])])}]

export { render, staticRenderFns }